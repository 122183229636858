import React from "react"
import { useTheme } from "emotion-theming"
import Layout from "../components/Layout"
import "../styles/global.css"
import { Section } from "../components/PageComponents/Section"
import SEO from "../components/seo"
import { ContentContainer } from "../components/PageComponents/ContentContainer"

const VillkorPage = () => {
  const theme = useTheme()

  return (
    <Layout>
      <SEO title="evoli - Villkor och förköpsinformation" />
      <Section bgColor={theme.colors.primaryBackground}>
        <ContentContainer>
          <h4>Villkor</h4>
          <p>
            I villkoret kan du i detalj läsa vad försäkringen gäller för.
            Villkoret tillsammans med ditt försäkringsbrev berättar hur din
            försäkring gäller. Skador regleras alltid enligt villkoret.
            <br />
            <br />
            <strong>Bil</strong> <br />
            <a
              href="/forsakringsvillkor/Forsakringsvillkor_bil_2020-06-01.pdf"
              target="_blank"
            >
              Försäkringsvillkor bilförsäkring 2020-06-01
            </a>
            <br />
            <a
              href="/forsakringsvillkor/villkor_bilforsakring_2020-01-01.pdf"
              target="_blank"
            >
              Försäkringsvillkor bilförsäkring 2020-01-01
            </a>
            <br />
            <br />
            <strong>Produkt</strong> <br />
            <a
              href="/forsakringsvillkor/Produktforsakring_villkor_2021-05-01.pdf"
              target="_blank"
            >
              Försäkringsvillkor produktförsäkring 2021-05-01
            </a>
            <br />
            <br />
            <strong>Hem</strong> <br />
            <a
              // href="/forsakringsvillkor/Produktforsakring_villkor_2021-05-01.pdf"
              href="#"
              target="_blank"
            >
              Försäkringsvillkor hemförsäkring 2021-08-01
            </a>
          </p>

          <br />

          <h4>Förköpsinformation</h4>
          <p>
            Förköpsinformation är en sammanfattning av vad försäkringen omfattar
            och de viktigaste begräsningarna. Du har rätt att få denna
            information innan du köper en försäkring och det är viktigt att du
            läser den.
            <br />
            <br />
            <strong>Bil</strong> <br />
            <a
              href="/forkopsinformation/forkopsinformation_bilforsakring_2020-06-01.pdf"
              target="_blank"
            >
              Förköpsinformation bilförsäkring 2020-06-01
            </a>
            <br />
            <a
              href="/forkopsinformation/forkopsinformation_bilforsakring_2020-01-01.pdf"
              target="_blank"
            >
              Förköpsinformation bilförsäkring 2020-01-01
            </a>
            <br />
            <br />
            <strong>Produkt</strong> <br />
            <a
              href="/forkopsinformation\Forkopsinformation-Produktforsakring.pdf"
              target="_blank"
            >
              Förköpsinformation produktförsäkring
            </a>
            <br />
            <br />
            <strong>Hem</strong> <br />
            <a
              // href="/forkopsinformation\Forkopsinformation-Produktforsakring.pdf"
              href="#"
              target="_blank"
            >
              Förköpsinformation hemförsäkring
            </a>
          </p>

          <br />

          <h4>Produktfaktablad</h4>
          <p>
            Produktfaktabladet är en europeisk variant på förköpsinformation som
            du ska ta del av innan du köper försäkringen.
            <br />
            <br />
            <strong>Bil</strong> <br />
            <a href="/produktfaktablad/senaste.pdf" target="_blank">
              Produktfaktablad bilförsäkring
            </a>
            <br />
            <br />
            <strong>Produkt</strong> <br />
            <a
              href="/produktfaktablad\IPID_produkt_2021-05-01.pdf"
              target="_blank"
            >
              Produktfaktablad produktförsäkring
            </a>
            <br />
            <br />
            <strong>Hem</strong> <br />
            <a
              // href="/produktfaktablad\Produktfaktablad-Produktforsakring.pdf"
              href="#"
              target="_blank"
            >
              Produktfaktablad hemförsäkring
            </a>
          </p>
        </ContentContainer>
      </Section>
    </Layout>
  )
}

export default VillkorPage
